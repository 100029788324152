import ActionButton from "components/Buttons/ActionButton";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductsListEditModal from "./ProductsListEditModal/ProductsListEditModal";
import useDisableBodyScroll from "helpers/DisableBodyScroll";
import ProductTree from "./ProductTree";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  PRODUCT_CATEGORY_COLLECTION_QUERY,
  PRODUCT_LIST_CATEGORYWISE,
  PRODUCT_LIST_ITEM_QUERY,
} from "modules/Products/Services/Queries/Queries";
import ProductListTable from "./ProductListTable";
import { getParent, getParticularValue } from "helpers/utils";
import { LoadingDesign } from "helpers";

const ProductsList = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [item, setItem] = useState<
    {
      id: number;
      name: string;
      children: [] | any[];
    }[]
  >([]);
  const [editProductList, setEditProductList] = useState<number>();
  const [product, setProduct] = useState<{
    coverImageFile: string;
    coverImageName: string;
    coverImageUid: string;
    description: any;
    id: number;
    link: any;
    name: string | null;
    price: string | null;
    priceMethod: string | null;
    priceOffset: string | null;
	customId: string | null;
    productCategory: {
      id: number;
      name: string | null;
    };
    productOptionsProducts: any[];
  }>();
  const [productCategoriesId, setProductCategoriesId] = useState<{
    id: number;
    name: string;
  }>();
  const navigate = useNavigate();
  useDisableBodyScroll(showEditModal);
  const [
    fetchProductListCategorywise,
    {
      data: productListCategorywise,
      refetch: refetchProductListCategorywise,
      loading: loadingProductList,
    },
  ] = useLazyQuery(PRODUCT_LIST_CATEGORYWISE, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  const { data: productCategories, loading: loadingProductCategory } = useQuery(
    PRODUCT_CATEGORY_COLLECTION_QUERY,
    {
      variables: {
        requiredFields: ["id", "name"],
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const [fetchSingleProduct, { data: singleProduct, loading: productLoading }] =
    useLazyQuery(PRODUCT_LIST_ITEM_QUERY, {
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    if (singleProduct) {
      setProduct(singleProduct?.product);
    }
  }, [singleProduct]);

  useEffect(() => {
    if (productCategories) {
      setProductCategoriesId({
        id: productCategories?.productCategories[0]?.id,
        name: productCategories?.productCategories[0]?.name,
      });
      setItem(productCategories?.productCategories);
    }
  }, [productCategories]);

  useEffect(() => {
    if (productCategoriesId) {
      fetchProductListCategorywise({
        variables: {
          productCategoryId: productCategoriesId?.id,
          limit: +pageSize,
          page: +currentPage,
        },
      });
    }
  }, [
    fetchProductListCategorywise,
    pageSize,
    currentPage,
    productCategoriesId,
  ]);

  let productList: any[] = [];

  getParent(item, productCategoriesId?.id)?.map((id: number) => {
    productList.push(getParticularValue(item, id));
    return null;
  });

  return (
    <div className="mt-[10px] grid grid-cols-1 lg:grid-cols-11">
      <div className="col-span-3">
        <h3 className="px-3 text-[22px] leading-10 text-[#393939]">
          Products Catergory
        </h3>
        {loadingProductCategory ? (
          <LoadingDesign />
        ) : productCategories?.productCategories?.length > 0 ? (
          <div className="space-y-[6px] pl-3 text-[13px] text-[#0088cc] leading-5 min-w-full max-w-[450px]">
            <ProductTree
              treeData={item}
              level={0}
              setProductCategoriesId={setProductCategoriesId}
            />
          </div>
        ) : (
          <p className="w-full h-[500px] grid place-content-center text-gray-400">
            Oops, No Product Category
          </p>
        )}
      </div>
      <div className="col-span-8 space-y-2">
        <h3 className="px-3 text-[22px] leading-10 text-[#393939]">
          Products List
        </h3>
        <div className="px-3 space-y-2">
          <p className="w-fit">
            {productList?.map((productName, index) => {
              return (
                <Fragment key={index}>
                  <i
                    className={`icon-angle-right text-[#393939] ${
                      index === 0 ? "hidden" : "inline-block"
                    }`}
                  />
                  <span
                    className="text-[#0088cc] hover:text-[#005580] hover:underline text-[13px] leading-5 cursor-pointer"
                    onClick={() => {
                      setProductCategoriesId({
                        id: productName?.id,
                        name: productName?.name,
                      });
                    }}
                  >
                    {productName?.name}
                  </span>
                </Fragment>
              );
            })}
          </p>

          <ActionButton
            buttonName="Edit table"
            icon="icon-edit"
            className="bg-[#87B87F] border-[#87B87F] hover:bg-[#629B58]"
            onClick={() => navigate("/price_table/index")}
          />

          <ActionButton
            buttonName="Add"
            icon="icon-plus"
            className="ml-2 bg-[#87B87F] border-[#87B87F] hover:bg-[#629B58]"
            onClick={() => {
              setShowEditModal(true);
              setEditProductList(undefined);
              setProduct(undefined);
            }}
          />
        </div>
        {loadingProductList ? (
          <LoadingDesign />
        ) : productListCategorywise?.productsByCategory?.dataCollection
            ?.length > 0 ? (
          <ProductListTable
            currentPage={currentPage}
            productList={
              productListCategorywise?.productsByCategory?.dataCollection
            }
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setEditProductList={setEditProductList}
            setPageSize={setPageSize}
            setShowEditModal={setShowEditModal}
            length={
              productListCategorywise?.productsByCategory?.totalNumberOfItems
            }
            refetchProductListCategorywise={refetchProductListCategorywise}
            fetchSingleProduct={fetchSingleProduct}
          />
        ) : (
          <p className="w-full h-[500px] grid place-content-center text-gray-400">
            Oops, No product list
          </p>
        )}
        {showEditModal && (
          <ProductsListEditModal
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            editProductList={editProductList}
            productCategoriesId={productCategoriesId}
            product={product}
            productLoading={productLoading}
            refetchProductListCategorywise={refetchProductListCategorywise}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsList;
