import React, { FC, useEffect } from "react";
import { Transition } from "@headlessui/react";
import NormalButton from "components/Buttons/NormalButton";
import { Select, Text, TextArea } from "components/Form";

const ProductsListEditModalCreateElement: FC<{
  deleteCoverImage: boolean;
  file: string | any;
  fileName: string | any;
  register: Function;
  onUploadFileChange: Function;
  setDeleteCoverImage: Function;
  errors: any;
  selectOptionsProductCategories: { id: number; name: string }[];
  editProductList: number | undefined;
  productCategoriesId: { id: number; name: string } | undefined;
  product:
    | {
        coverImageFile: string;
        coverImageName: string;
        coverImageUid: string;
        description: any;
	    customId: string | null;
        id: number;
        link: any;
        name: string | null;
        price: string | null;
        priceMethod: string | null;
        priceOffset: string | null;
        productCategory: {
          id: number;
          name: string | null;
        };
        productOptionsProducts: any[];
      }
    | undefined;
  setValue: Function;
  loading: boolean;
}> = ({
  deleteCoverImage,
  file,
  fileName,
  register,
  onUploadFileChange,
  setDeleteCoverImage,
  errors,
  selectOptionsProductCategories,
  editProductList,
  productCategoriesId,
  product,
  setValue,
  loading,
}) => {
  useEffect(() => {
    if (product) {
      setTimeout(() => {
        setValue("productCategory", product?.productCategory?.id);
        setValue("name", product?.name);
        setValue("priceOffset", product?.priceOffset);
        setValue("description", product?.description);
		setValue("customId", product?.customId);
        setValue(
          "priceMethod",
          product?.priceMethod?.toLowerCase() === "fixed"
            ? "Fixed"
            : product?.priceMethod?.toLowerCase() === "table"
              ? "Table"
              : "",
        );
      });
    }
  }, [product, setValue, editProductList]);
  useEffect(() => {
    if (productCategoriesId) {
      setTimeout(() => {
        setValue("productCategory", productCategoriesId?.id);
      });
    }
  }, [productCategoriesId, setValue]);

  return (
    <div className="space-y-5 p-4">
      <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px]">
        <label
          htmlFor="logo"
          className="cursor-pointer text-sm leading-5 w-min h-min whitespace-nowrap"
        >
          Logo
        </label>

        <div>
          <Transition
            show={!deleteCoverImage}
            className="space-y-2"
            enter="transition duration-1000 ease-out"
            enterFrom="transform scale-y-0 opacity-0 origin-top"
            enterTo="transform scale-100 opacity-100 origin-top"
            leave="transition duration-1000 ease-out"
            leaveFrom="transform scale-100 opacity-100 origin-top"
            leaveTo="transform scale-y-0 opacity-0 origin-top"
          >
            <img
              src={file}
              alt={fileName}
              className={`object-cover shadow-md w-16 h-16 ${
                file ? "block" : "hidden"
              }`}
            />
            <input
              type="file"
              id="logo"
              className="text-[13px] leading-5 font-medium focus:rounded py-[2px]"
              {...register("logo")}
              accept="image/jpeg,image/png,image/jpg,image/gif,image/webp,image/svg+xml,image/svg"
              onChange={onUploadFileChange}
              size={1}
            />
          </Transition>
          <NormalButton
            buttonName="Delete Logo"
            buttonType="button"
            icon="icon-trash-empty"
            className={`${
              deleteCoverImage
                ? "bg-[#df261f] hover:bg-[#f15a55] boder border-[#df261f] hover:border-[#f15a55] px-3 py-1 max-w-[300px] text-white"
                : "bg-[#0078AE] hover:bg-[#3a8bb1] boder border-[#0078AE] hover:border-[#3a8bb1] px-3 py-1 max-w-[300px] text-white"
            } ${product?.coverImageUid ? "block" : "hidden"}`}
            onClick={() => {
              setDeleteCoverImage(!deleteCoverImage);
            }}
          />
        </div>
      </div>
      <div className="grid  grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px]">
        <label
          htmlFor="name"
          className="cursor-pointer text-sm w-min h-min whitespace-nowrap"
        >
          Name
        </label>
        <Text
          errors={errors}
          name="name"
          register={register}
          hideError
          hideIcon
          className="bg-[#ffffff] text-sm max-w-[300px]"
        />
      </div>
      <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px]">
        <label
          htmlFor="productCategory"
          className="cursor-pointer text-sm leading-5 w-min h-min whitespace-nowrap"
        >
          Product category
        </label>

        <Select
          errors={errors}
          name="productCategory"
          register={register}
          hideError
          classForSelect="focus:border-[#F59942] focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)] bg-[#dcdcdc]"
          optionsWithObject={selectOptionsProductCategories}
          className="max-w-[300px]"
          hideSelect
        />
      </div>
      <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_70px_70px]">
        <label
          htmlFor="fixed"
          className="cursor-pointer text-sm leading-5 h-min w-min whitespace-nowrap"
        >
          Price Method
        </label>

        <div className="flex items-center">
          <input
            type="radio"
            id="fixed"
            className="text-sm leading-5"
            {...register("priceMethod")}
            value="Fixed"
          />
          <label
            htmlFor="fixed"
            className="cursor-pointer text-sm leading-5 ml-2"
          >
            Fixed
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            id="table"
            className="text-sm leading-7"
            {...register("priceMethod")}
            value="Table"
          />
          <label
            htmlFor="table"
            className="cursor-pointer text-sm leading-5 ml-2"
          >
            Table
          </label>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px]">
        <label
          htmlFor="priceOffset"
          className="cursor-pointer text-sm w-min h-min whitespace-nowrap"
        >
          Price offset
        </label>
        <Text
          errors={errors}
          name="priceOffset"
          register={register}
          hideError
          hideIcon
          className="bg-[#ffffff] text-sm leading-5 max-w-[300px]"
          onKeyPress={(event: any) => {
            if (!/[0-9.]/.test(event?.key)) event?.preventDefault();
          }}
        />
      </div>
	  <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px]">
		  <label
			  htmlFor="description"
			  className="cursor-pointer text-sm leading-5 "
		  >
			  Description
		  </label>
		  <TextArea
				errors={errors}
				name="description"
				register={register}
			  className="text-sm leading-5 max-w-[300px]"
		    classForTextArea="focus:border-[#F59942] resize-none focus:outline-none focus:shadow-[0px_0px_0px_2px_rgb(245_153_66/30%)]"
		  />
	  </div>
		<div className="grid grid-cols-1 gap-y-1 sm:grid-cols-[130px_300px]">
			<label
				htmlFor="customId"
				className="cursor-pointer text-sm leading-5 "
			>
				Custom Id
			</label>
			<Text
				errors={errors}
				name="customId"
				register={register}
				hideError
				hideIcon
				className="bg-[#ffffff] text-sm leading-5 max-w-[300px]"
			/>
		</div>
      <NormalButton
        buttonName="Save"
        className={`border border-black rounded px-2 sm:ml-[130px] hover:bg-[rgb(239,239,239)] ${
          editProductList ? "hidden lg:block" : "block"
        }`}
        buttonType="submit"
        icon={loading ? "icon-spin4" : ""}
        loading={loading}
      />
    </div>
  );
};

export default ProductsListEditModalCreateElement;
