import { gql } from "@apollo/client";

export const PRODUCT_CATEGORY_ITEM_QUERY = gql`
  query productCategory($id: Int!, $requiredFields: [String]!) {
    productCategory(id: $id, requiredFields: $requiredFields)
  }
`;

export const PRODUCT_CATEGORY_COLLECTION_QUERY = gql`
  query productCategories($requiredFields: [String]!) {
    productCategories(requiredFields: $requiredFields)
  }
`;
export const PRODUCT_OPTION_ITEM_QUERY = gql`
  query productOption($id: Int!, $requiredFields: [String]!) {
    productOption(id: $id, requiredFields: $requiredFields)
  }
`;
export const PRODUCT_OPTION_COLLECTION_QUERY = gql`
  query productOptions($requiredFields: [String]!) {
    productOptions(requiredFields: $requiredFields)
  }
`;
export const PRODUCT_PRICE_TABLE_ITEM_QUERY = gql`
  query productPriceTable($id: Int!) {
    productPriceTable(id: $id) {
      id
      name
      isDefault
      createdAt
      updatedAt
      productOption {
        id
        title
      }
      productCategory {
        id
        name
      }
      productPriceTableValues {
        id
        productOption {
          id
          title
        }
        count
        price
        createdAt
        updatedAt
      }
    }
  }
`;
export const PRODUCT_PRICE_TABLE_COLLECTION_QUERY = gql`
  query productPriceTables($limit: Int, $page: Int) {
    productPriceTables(limit: $limit, page: $page) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
        productOption {
          id
          title
        }
        productCategory {
          id
          name
        }
        productPriceTableValues {
          id
          productOption {
            id
            title
          }
          count
          price
          createdAt
          updatedAt
        }
      }
      totalNumberOfItems
    }
  }
`;

export const PRODUCT_LIST_COLLECTION_QUERY = gql`
  query products {
    products {
      id
      name
      price
      description
      priceMethod
      priceOffset
      productCategory {
        id
        name
      }
      productOptionsProducts {
        id
        productOption {
          id
          title
        }
        createdAt
      }
      coverImageName
      coverImageUid
      coverImageFile
      link
    }
  }
`;
export const PRODUCT_LIST_ITEM_QUERY = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      customId
      name
      price
      description
      priceMethod
      priceOffset
      productCategory {
        id
        name
      }
      productOptionsProducts {
        id
        productOption {
          id
          title
        }
        createdAt
      }

      coverImageName
      coverImageUid
      coverImageFile
      link
    }
  }
`;

export const PRODUCT_LIST_FILTER_QUERY = gql`
  query filterProducts(
    $id: FilterProductsId
    $name: FilterProductsName
    $createdAt: FilterProductsDateCreatedAt
    $updatedAt: FilterProductsDateUpdatedAt
    $price: FilterProductsPrice
    $description: FilterProductsDescription
    $priceMethod: FilterProductsPriceMethods
    $priceOffset: FilterProductsPriceOffset
    $coverImageName: String
    $productPriceTable: FilterProductsPriceTable
    $link: FilterProductsLink
    $productCategory: FilterProductsProductCategory
    $globalSearch: String
    $limit: Int
    $page: Int
    $sortBy: ProductSortBy
  ) {
    filterProducts(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      price: $price
      description: $description
      priceMethod: $priceMethod
      priceOffset: $priceOffset
      coverImageName: $coverImageName
      limit: $limit
      page: $page
      productPriceTable: $productPriceTable
      link: $link
      sortBy: $sortBy
      productCategory: $productCategory
      globalSearch: $globalSearch
    ) {
      dataCollection {
        id
        name
        createdAt
        updatedAt
        priceMethod
        price
        description
      }
      totalNumberOfItems
    }
  }
`;
export const PRODUCT_LIST_CATEGORYWISE = gql`
  query productsByCategory($productCategoryId: Int!, $limit: Int, $page: Int) {
    productsByCategory(
      productCategoryId: $productCategoryId
      limit: $limit
      page: $page
    ) {
      dataCollection {
        id
        name
        price
        description
        priceMethod
        priceOffset
        productCategory {
          id
          name
        }
        productOptionsProducts {
          id
          productOption {
            id
            title
          }
          createdAt
        }
        link
      }
      totalNumberOfItems
    }
  }
`;

export const PRODUCT_OPTIONS_BY_PRODUCT = gql`
  query productOptionsByProduct($productId: Int!) {
    productOptionsByProduct(productId: $productId) {
      childOptions {
        id
        createdAt
        updatedAt
        productOption {
          title
        }
        product {
          id
          name
        }
      }
    }
  }
`;
