import { useForm } from "react-hook-form";
import { FC, Fragment, useEffect, useState } from "react";
import useDisableBodyScroll from "helpers/DisableBodyScroll";
import { fileToBase64, getExtension, getParent } from "helpers/utils";
import {
  PRODUCT_CATEGORY_COLLECTION_QUERY,
  PRODUCT_OPTION_COLLECTION_QUERY,
} from "modules/Products/Services/Queries/Queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST,
} from "modules/Products/Services/Mutations/Mutation";
import ProductsListEditModalOptionElement from "./ProductsListEditModalOptionElement";
import ProductsListEditModalCreateElement from "./ProductsListEditModalCreateElement";
import { LoadingDesign } from "helpers";

export interface FormType {
  logo: any;
  name: string;
  productCategory: string | number;
  priceMethod: string;
  priceOffset: string | number;
  description: string;
  customId: string;
  productOption: any;
}

const ProductsListEditModal: FC<{
  showEditModal: boolean;
  setShowEditModal: Function;
  editProductList?: number;
  productCategoriesId: { id: number; name: string } | undefined;
  product:
    | {
        coverImageFile: string;
        coverImageName: string;
        coverImageUid: string;
        description: any;
	    customId: string | null;
        id: number;
        link: any;
        name: string | null;
        price: string | null;
        priceMethod: string | null;
        priceOffset: string | null;
        productCategory: {
          id: number;
          name: string | null;
        };
        productOptionsProducts: any[];
      }
    | undefined;
  productLoading: boolean;
  refetchProductListCategorywise: Function;
}> = ({
  showEditModal,
  setShowEditModal,
  editProductList,
  productCategoriesId,
  product,
  productLoading,
  refetchProductListCategorywise,
}) => {
  const [productCategories, setProductCategories] = useState<
    {
      id: number;
      name: string;
      children: [] | any[];
    }[]
  >([]);
  const [deleteCoverImage, setDeleteCoverImage] = useState(false);
  const [productOptions, setProductOption] = useState<
    {
      id: number;
      name: string;
      children: [] | any[];
    }[]
  >([]);
  const { data: productCategoriesList } = useQuery(
    PRODUCT_CATEGORY_COLLECTION_QUERY,
    {
      variables: {
        requiredFields: ["id", "name"],
      },
    },
  );
  const [fetchProductOption, { data: productOptionList }] = useLazyQuery(
    PRODUCT_OPTION_COLLECTION_QUERY,
    {
      variables: {
        requiredFields: ["id", "title"],
      },
    },
  );
  const [createProductList] = useMutation(CREATE_PRODUCT_LIST);
  const [updateProductList] = useMutation(UPDATE_PRODUCT_LIST);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormType>();
  const [file, setFile] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  useEffect(() => {
    if (product?.coverImageFile && product?.coverImageName) {
      setFile(
        `data:image/${
          getExtension(product?.coverImageName) === "svg"
            ? "svg+xml"
            : getExtension(product?.coverImageName)
        };base64,${product?.coverImageFile}`,
      );
    }
  }, [product]);

  const onUploadFileChange = (event: any) => {
    if (event?.target?.files[0]?.size > 1e7) {
      if (product?.coverImageFile && product?.coverImageName) {
        setFile(
          `data:image/${getExtension(
            product?.coverImageName,
          )};base64,${product?.coverImageFile}`,
        );
        setFileName(product?.coverImageName);
        setValue("logo", "");
      } else {
        setValue("logo", "");
        setFile(undefined);
        setFileName(undefined);
      }
      return alert("Maximum file size limit is 1MB.");
    } else {
      fileToBase64(event?.target?.files[0], (err: any, result: any) => {
        if (result) {
          setFile(result);
          setFileName(event.target.files[0]?.name);
        }
      });
    }
    if (event.target.files < 1 || !event.target.validity.valid) {
      return;
    }
  };
  const [loading, setLoading] = useState(false);
  const productlistEditFormHandler = (data: any) => {
    if (editProductList) {
      let productOptionIds: number[] = [];
      Object?.entries(data?.productOption)?.map(([key, value], index) => {
        if (value === true) {
          productOptionIds.push(+key);
        }
        return null;
      });
      setLoading(true);
      updateProductList({
        variables: {
          id: editProductList,
          coverImageFile: file && data.logo[0]?.name ? file : undefined,
          coverImageFileName:
            file && data.logo[0]?.name ? data.logo[0]?.name : undefined,
          name: data?.name,
          productCategoryId: +data?.productCategory,
          priceMethod: data?.priceMethod,
          priceOffset: +data?.priceOffset,
          description: data?.description ? data?.description : undefined,
	      customId: data?.customId ? data?.customId : undefined,
          productOptionsIds: productOptionIds,
          deleteCoverImage: file ? deleteCoverImage : undefined,
        },
      })
        .then(() => {
          setLoading(false);
          setShowEditModal(false);
          refetchProductListCategorywise();
          setValue("logo", "");
          setFile(undefined);
          setFileName(undefined);
        })
        .catch((errors: any) => {
          setLoading(false);
          // alert(error?.message);(errors?.message);
        });
    } else {
      setLoading(true);
      createProductList({
        variables: {
          coverImageFile: file && data.logo[0]?.name ? file : undefined,
          coverImageFileName:
            file && data.logo[0]?.name ? data.logo[0]?.name : undefined,
          name: data?.name ? data?.name : undefined,
          productCategoryId: +data?.productCategory
            ? +data?.productCategory
            : undefined,
          priceMethod: data?.priceMethod ? data?.priceMethod : undefined,
          priceOffset:
            data?.priceOffset === ""
              ? undefined
              : data?.priceOffset
                ? +data?.priceOffset
                : undefined,
          description: data?.description ? data?.description : undefined,
		  customId: data?.customId ? data?.customId : undefined,
        },
      })
        .then(() => {
          setLoading(false);
          setShowEditModal(false);
          refetchProductListCategorywise();
          setValue("logo", "");
          setFile(undefined);
          setFileName(undefined);
        })
        .catch((errors: any) => {
          setLoading(false);
          // alert(error?.message);(errors?.message);
        });
    }
  };

  useDisableBodyScroll(showEditModal);

  useEffect(() => {
    if (editProductList) {
      fetchProductOption({
        variables: {
          requiredFields: ["id", "title"],
        },
      });
    }
  }, [editProductList, fetchProductOption]);

  useEffect(() => {
    const productsOptionConvertionHandler: any = (
      productOptions: {
        id: number;
        title: string;
        children: [] | any[];
      }[],
    ) => {
      if (productOptions?.length === 0) return null;

      return productOptions?.map(
        (productOptions: {
          id: number;
          title: string;
          children: [] | any[];
        }) => {
          return {
            id: productOptions?.id,
            name: productOptions?.title,
            children: productsOptionConvertionHandler(productOptions?.children)
              ? productsOptionConvertionHandler(productOptions?.children)
              : [],
          };
        },
      );
    };
    if (productOptionList) {
      setProductOption(
        productsOptionConvertionHandler(productOptionList?.productOptions),
      );
    }
  }, [productOptionList]);

  useEffect(() => {
    if (productCategoriesList) {
      setProductCategories(productCategoriesList?.productCategories);
    }
  }, [productCategoriesList]);

  let selectOptionsProductCategories: any[] = [];
  const getSelectOptionsHandler = (treeData: any[]) => {
    if (treeData?.length === 0) return null;
    treeData?.map((treeData) => {
      selectOptionsProductCategories.push({
        id: treeData?.id,
        name: `${
          getParent(productCategories, treeData?.id)?.length === 1 ? "" : ""
        }${
          getParent(productCategories, treeData?.id)?.length === 2 ? "- " : ""
        }${
          getParent(productCategories, treeData?.id)?.length === 3 ? "- - " : ""
        }${treeData?.name}`,
      });
      if (treeData?.children?.length > 0) {
        getSelectOptionsHandler(treeData?.children);
      }
      return treeData;
    });
  };
  getSelectOptionsHandler(productCategories);
  return (
    <div
      className={`fixed inset-0 bg-transparent z-50 p-2 flex flex-row ${
        editProductList ? "" : "lg:items-center"
      } justify-center w-full`}
    >
      <div
        className="fixed inset-0 bg-black/60 -z-20"
        onClick={() => {
          if (!productLoading) {
            setShowEditModal(false);
          }
        }}
      />
      <div
        className={`${
          editProductList ? "" : "w-[340px] sm:w-[560px] mx-auto"
        }  bg-[#E4E6E9] shadow-[0_0_64px_#000] h-fit`}
      >
        {productLoading ? (
          <div className="w-[300px] sm:w-[400px] lg:w-[600px]">
            <LoadingDesign />
          </div>
        ) : (
          <Fragment>
            <h3 className="bg-[#0078AE] text-[#000] font-semibold flex justify-between py-2 px-2">
              <span className="text-[16px] leading-5">
                {editProductList ? "Edit Product" : "New Product"}
              </span>
              <span className="inline-flex justify-center items-center text-red-600">
                <i
                  onClick={() => {
                    if (!productLoading) {
                      setShowEditModal(false);
                    }
                  }}
                  className="icon-cancel cursor-pointer text-white"
                />
              </span>
            </h3>
            <form
              onSubmit={handleSubmit(productlistEditFormHandler)}
              className={`${
                editProductList
                  ? "grid grid-cols-1 lg:grid-cols-2 max-h-[93vh] overflow-y-auto"
                  : ""
              }`}
            >
              <ProductsListEditModalCreateElement
                deleteCoverImage={deleteCoverImage}
                editProductList={editProductList}
                errors={errors}
                file={file}
                fileName={fileName}
                onUploadFileChange={onUploadFileChange}
                register={register}
                selectOptionsProductCategories={selectOptionsProductCategories}
                setDeleteCoverImage={setDeleteCoverImage}
                productCategoriesId={productCategoriesId}
                product={product}
                setValue={setValue}
                loading={loading}
              />
              <ProductsListEditModalOptionElement
                editProductList={editProductList}
                productOptions={productOptions}
                register={register}
                setValue={setValue}
                product={product}
                loading={loading}
              />
            </form>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ProductsListEditModal;
